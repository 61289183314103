<template>
    <div>
        <el-row>
            <div>test</div>
            <div ref="myChart" style="width: 95%;height: 500px;"></div>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "EChartDemo",
        data() {
          return {

          }
        },
        mounted() {
            // this.drawLineChart();
            this.test();
        },
        methods: {
            drawLineChart() {
                const labels = [
                    '12am',
                    '', '', '3', '', '', '6',
                    '', '', '9', '', '', '12pm',
                    '', '', '', '', '', '6',
                    '', '', '9', '', '',
                    '12am'
                ];

                const data = [150, 290, 10, 212, 120, 80, 95, 150, 290, 10, 212, 120, 80, 95, 150, 290, 10, 212, 120, 80,
                    95, 150, 290, 10, 212];

                let lineChart = this.$echarts.init(this.$refs.myChart);
                let option = {
                    title: {
                        text: 'ECharts 示例'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: labels
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            type: 'line',
                            emphasis: {
                                focus: 'series'
                            },
                            data: data
                        }
                    ]
                };
                lineChart.setOption(option);
            },

            fun (x,u,a){
                return  (1 / Math.sqrt(2 * Math.PI) * a) * Math.exp( -1 * ((x-u) * (x-u)) / (2 * a * a));
            },

            test() {


                let datas = [{
                        "data": {
                            "boxplot": {
                                "q1": "5043.00",
                                "q3": "9296.00",
                                "median": "7063.00",
                                "mean": "7248.59",
                                "lower": "-1336.50",
                                "upper": "15675.50"
                            },
                            "stdPlot": {
                                "xaxis": "-6486,-6103,-5720,-5337,-4954,-4571,-4188,-3805,-3422,-3039,-2656,-2273,-1890,-1507,-1124,-741,-358,25,408,791,1174,1557,1940,2323,2706,3090,3473,3856,4239,4622,5005,5388,5771,6154,6537,6920,7303,7686,8069,8452,8835,9218,9601,9984,10367,10750,11133,11516,11899,12282,12666,13049,13432,13815,14198,14581,14964,15347,15730,16113,16496,16879,17262,17645,18028,18411,18794,19177,19560,19943,20326,20709,21092,21475,21858,22242,22625,23008,23391,23774,24157,24540,24923,25306,25689,26072,26455,26838,27221,27604,27987,28370,28753,29136,29519,29902,30285,30668,31051,31434",
                                "mean": "7248.59",
                                "lower": "-2729.19",
                                "upper": "17226.37",
                                "yaxis": "1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17,9,2,3,3,2,1,4,7,9,29,30,42,32,25,48,33,41,33,38,36,29,28,33,23,15,20,43,18,19,12,13,7,5,7,2,3,1,2,1,0,0,0,2,1,0,1,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,1",
                                "stdev": "3325.93"
                            }
                        }, "idx": 1
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "49032.00",
                                "q3": "65280.00",
                                "median": "55040.00",
                                "mean": "59582.43",
                                "lower": "24660.00",
                                "upper": "89652.00"
                            },
                            "stdPlot": {
                                "xaxis": "804,2410,4016,5622,7228,8835,10441,12047,13653,15259,16866,18472,20078,21684,23290,24897,26503,28109,29715,31321,32928,34534,36140,37746,39352,40959,42565,44171,45777,47383,48990,50596,52202,53808,55414,57021,58627,60233,61839,63445,65052,66658,68264,69870,71476,73083,74689,76295,77901,79507,81114,82720,84326,85932,87538,89145,90751,92357,93963,95569,97176,98782,100388,101994,103600,105207,106813,108419,110025,111631,113238,114844,116450,118056,119662,121269,122875,124481,126087,127693,129300,130906,132512,134118,135724,137331,138937,140543,142149,143755,145362,146968,148574,150180,151786,153393,154999,156605,158211,159817",
                                "mean": "59582.43",
                                "lower": "-20382.83",
                                "upper": "139547.69",
                                "yaxis": "3,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,2,0,0,1,0,5,3,5,5,2,5,2,3,4,3,0,4,1,0,1,0,1,0,1,1,0,0,2,0,0,1,0,2,0,1,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,1",
                                "stdev": "26655.09"
                            }
                        }, "idx": 6
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "14130.00",
                                "q3": "19868.00",
                                "median": "16686.00",
                                "mean": "17212.80",
                                "lower": "5523.00",
                                "upper": "28475.00"
                            },
                            "stdPlot": {
                                "xaxis": "351,1051,1751,2452,3152,3852,4553,5253,5953,6654,7354,8054,8755,9455,10155,10856,11556,12256,12957,13657,14358,15058,15758,16459,17159,17859,18560,19260,19960,20661,21361,22061,22762,23462,24162,24863,25563,26263,26964,27664,28365,29065,29765,30466,31166,31866,32567,33267,33967,34668,35368,36068,36769,37469,38169,38870,39570,40270,40971,41671,42372,43072,43772,44473,45173,45873,46574,47274,47974,48675,49375,50075,50776,51476,52176,52877,53577,54277,54978,55678,56379,57079,57779,58480,59180,59880,60581,61281,61981,62682,63382,64082,64783,65483,66183,66884,67584,68284,68985,69685",
                                "mean": "17212.80",
                                "lower": "-1088.38",
                                "upper": "35513.99",
                                "yaxis": "7,1,0,1,1,1,1,1,0,1,1,1,1,3,4,7,16,24,30,20,35,33,33,28,38,21,27,23,27,20,19,12,13,6,6,7,5,2,1,2,2,0,0,0,2,0,1,1,1,0,2,0,1,0,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1",
                                "stdev": "6100.40"
                            }
                        }, "idx": 3
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "122899.00",
                                "q3": "193020.00",
                                "median": "144330.00",
                                "mean": "158987.64",
                                "lower": "17717.50",
                                "upper": "298201.50"
                            },
                            "stdPlot": {
                                "xaxis": "11657,14573,17490,20406,23323,26240,29156,32073,34989,37906,40823,43739,46656,49573,52489,55406,58322,61239,64156,67072,69989,72906,75822,78739,81655,84572,87489,90405,93322,96238,99155,102072,104988,107905,110822,113738,116655,119571,122488,125405,128321,131238,134155,137071,139988,142904,145821,148738,151654,154571,157488,160404,163321,166237,169154,172071,174987,177904,180820,183737,186654,189570,192487,195404,198320,201237,204153,207070,209987,212903,215820,218737,221653,224570,227486,230403,233320,236236,239153,242069,244986,247903,250819,253736,256653,259569,262486,265402,268319,271236,274152,277069,279986,282902,285819,288735,291652,294569,297485,300402",
                                "mean": "158987.64",
                                "lower": "6189.34",
                                "upper": "311785.94",
                                "yaxis": "1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,2,0,2,1,1,1,0,2,5,4,4,9,6,5,6,5,6,4,4,6,4,4,3,3,3,2,0,2,3,2,2,0,2,2,2,1,3,2,1,1,2,2,1,1,2,1,0,1,2,2,0,3,2,0,1,0,1,0,0,0,1,1,0,1,0,1,2,0,0,2,0,0,0,1",
                                "stdev": "50932.77"
                            }
                        }, "idx": 5
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "97340.00",
                                "q3": "97340.00",
                                "median": "97340.00",
                                "mean": "97340.00",
                                "lower": "97340.00",
                                "upper": "97340.00"
                            },
                            "stdPlot": {
                                "xaxis": "97340",
                                "mean": "97340.00",
                                "lower": "97340.00",
                                "upper": "97340.00",
                                "yaxis": "1",
                                "stdev": "0.00"
                            }
                        }, "idx": 9
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "57222.00",
                                "q3": "86283.00",
                                "median": "70943.00",
                                "mean": "72367.75",
                                "lower": "13630.50",
                                "upper": "129874.50"
                            },
                            "stdPlot": {
                                "xaxis": "914,2740,4567,6394,8221,10047,11874,13701,15528,17355,19181,21008,22835,24662,26488,28315,30142,31969,33796,35622,37449,39276,41103,42929,44756,46583,48410,50237,52063,53890,55717,57544,59370,61197,63024,64851,66678,68504,70331,72158,73985,75811,77638,79465,81292,83119,84945,86772,88599,90426,92253,94079,95906,97733,99560,101386,103213,105040,106867,108694,110520,112347,114174,116001,117827,119654,121481,123308,125135,126961,128788,130615,132442,134268,136095,137922,139749,141576,143402,145229,147056,148883,150709,152536,154363,156190,158017,159843,161670,163497,165324,167150,168977,170804,172631,174458,176284,178111,179938,181765",
                                "mean": "72367.75",
                                "lower": "2215.91",
                                "upper": "142519.60",
                                "yaxis": "4,0,0,2,0,0,1,0,1,0,0,3,0,0,0,1,2,0,0,0,1,1,1,3,5,4,8,13,16,14,9,15,10,13,9,16,10,12,14,16,10,9,10,12,12,14,7,4,3,9,8,9,8,8,3,2,3,4,5,5,4,3,2,1,2,1,1,0,1,2,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1",
                                "stdev": "23383.95"
                            }
                        }, "idx": 4
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "3429920.00",
                                "q3": "3429920.00",
                                "median": "3429920.00",
                                "mean": "3429920.00",
                                "lower": "3429920.00",
                                "upper": "3429920.00"
                            },
                            "stdPlot": {
                                "xaxis": "3429920",
                                "mean": "3429920.00",
                                "lower": "3429920.00",
                                "upper": "3429920.00",
                                "yaxis": "1",
                                "stdev": "0.00"
                            }
                        }, "idx": 8
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "306932.00",
                                "q3": "630336.00",
                                "median": "396162.00",
                                "mean": "450303.46",
                                "lower": "-178174.00",
                                "upper": "1115442.00"
                            },
                            "stdPlot": {
                                "xaxis": "5763,17287,28812,40337,51862,63387,74912,86437,97962,109487,121012,132537,144062,155587,167112,178636,190161,201686,213211,224736,236261,247786,259311,270836,282361,293886,305411,316936,328461,339985,351510,363035,374560,386085,397610,409135,420660,432185,443710,455235,466760,478285,489810,501334,512859,524384,535909,547434,558959,570484,582009,593534,605059,616584,628109,639634,651159,662684,674208,685733,697258,708783,720308,731833,743358,754883,766408,777933,789458,800983,812508,824033,835557,847082,858607,870132,881657,893182,904707,916232,927757,939282,950807,962332,973857,985382,996906,1008431,1019956,1031481,1043006,1054531,1066056,1077581,1089106,1100631,1112156,1123681,1135206,1146731",
                                "mean": "450303.46",
                                "lower": "-320409.39",
                                "upper": "1221016.31",
                                "yaxis": "2,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,1,0,0,0,2,1,1,3,2,0,1,2,0,2,0,1,0,0,0,2,1,0,1,0,1,0,1,0,0,0,0,0,0,1,1,1,1,0,0,1,0,0,1,1,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,1",
                                "stdev": "256904.28"
                            }
                        }, "idx": 7
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "25633.00",
                                "q3": "38221.00",
                                "median": "31039.00",
                                "mean": "31347.89",
                                "lower": "6751.00",
                                "upper": "57103.00"
                            },
                            "stdPlot": {
                                "xaxis": "687,2059,3432,4805,6178,7551,8923,10296,11669,13042,14415,15787,17160,18533,19906,21279,22651,24024,25397,26770,28143,29515,30888,32261,33634,35007,36379,37752,39125,40498,41871,43243,44616,45989,47362,48735,50107,51480,52853,54226,55599,56971,58344,59717,61090,62463,63835,65208,66581,67954,69327,70699,72072,73445,74818,76191,77563,78936,80309,81682,83055,84427,85800,87173,88546,89919,91291,92664,94037,95410,96783,98155,99528,100901,102274,103647,105019,106392,107765,109138,110511,111883,113256,114629,116002,117375,118747,120120,121493,122866,124239,125611,126984,128357,129730,131103,132475,133848,135221,136594",
                                "mean": "31347.89",
                                "lower": "-2573.71",
                                "upper": "65269.49",
                                "yaxis": "11,1,4,2,2,3,2,1,2,3,1,2,2,7,8,9,28,26,28,38,30,27,35,23,25,28,21,23,21,20,20,13,14,17,8,2,7,4,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1",
                                "stdev": "11307.20"
                            }
                        }, "idx": 2
                    }, {
                        "data": {
                            "boxplot": {
                                "q1": "1072.00",
                                "q3": "61673.00",
                                "median": "22848.00",
                                "mean": "149013.77",
                                "lower": "-89829.50",
                                "upper": "152574.50"
                            }, "stdPlot": {
                                "xaxis": "-4748361,-3093483,-1438605,216273,1871151,3526029,5180908,6835785,8490663,10145541,11800419,13455297,15110175,16765054,18419931,20074809,21729688,23384565,25039443,26694321,28349199,30004077,31658955,33313833,34968711,36623589,38278467,39933346,41588223,43243101,44897979,46552857,48207735,49862614,51517491,53172369,54827247,56482125,58137003,59791881,61446759,63101637,64756515,66411393,68066271,69721149,71376027,73030905,74685783,76340661,77995539,79650417,81305295,82960173,84615051,86269930,87924807,89579685,91234563,92889441,94544319,96199197,97854075,99508953,101163831,102818709,104473587,106128466,107783343,109438221,111093099,112747977,114402855,116057733,117712611,119367489,121022367,122677245,124332123,125987001,127641880,129296757,130951635,132606513,134261391,135916269,137571147,139226025,140880903,142535781,144190659,145845537,147500415,149155293,150810171,152465049,154119927,155774805,157429683,159084561",
                                "mean": "149013.77",
                                "lower": "-3277437.09",
                                "upper": "3575464.62",
                                "yaxis": "1,3,4,33557,1143,79,19,8,4,2,2,5,3,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1",
                                "stdev": "1142150.28"
                            }
                        }, "idx": 0
                    }]
                ;
                for (let i = 0; i < datas.length; i++) {
                    let myChart = this.$echarts.init(this.$refs.myChart);
                    let testData = [];
                    let data = [];
                    data.push(datas[i].data.boxplot.lower);
                    data.push(datas[i].data.boxplot.q1);
                    data.push(datas[i].data.boxplot.median);
                    data.push(datas[i].data.boxplot.q3);
                    data.push(datas[i].data.boxplot.upper);
                    testData.push(data);
                    let xdata = [datas[i].idx];

                    let option = {
                        title: [
                            {
                                text: 'upper: Q3 + 1.5 * IRQ \nlower: Q1 - 1.5 * IRQ',
                                borderColor: '#999',
                                borderWidth: 1,
                                textStyle: {
                                    fontSize: 14
                                },
                                left: '10%',
                                top: '85%'
                            }
                        ],
                        tooltip: {
                            trigger: 'item', //触发类型,数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
                            axisPointer: { //指示器类型。
                                type: 'shadow'
                            }
                        },
                        grid: { //直角坐标系网格。
                            //show: true,//default: false
                            left: '15%',
                            right: '15%',
                            bottom: '23%',
                            //borderWidth: 1,
                            //borderColor: '#000',
                        },
                        xAxis: { //X轴
                            type: 'category', //'category' 类目轴，适用于离散的类目数据，为该类型时必须通过 data 设置类目数据。
                            //data: data.axisData,
                            data: xdata,
                            boundaryGap: true, //类目轴中 boundaryGap 可以配置为 true 和 false。默认为 true，这时候刻度只是作为分隔线，标签和数据点都会在两个刻度之间的带(band)中间。
                            nameGap: 30, //坐标轴名称与轴线之间的距离。
                            splitArea: { //坐标轴在 grid 区域中的分隔区域，默认不显示。
                                //show: true, //是否显示分隔区域
                                //interval: 'auto', //坐标轴分隔区域的显示间隔，在类目轴中有效
                            },
                            axisLabel: { //坐标轴刻度标签的相关设置。
                                //formatter: 'expr {value}',  // 使用字符串模板，模板变量为刻度默认标签 {value}
                                show: true, //是否显示刻度标签。
                                //interval: 'auto', //坐标轴刻度标签的显示间隔，在类目轴中有效。
                                color: 'black',

                            },
                            splitLine: { //坐标轴在 grid 区域中的分隔线。
                                show: true, //是否显示分隔线。默认数值轴显示，类目轴不显示。
                                lineStyle: { //分隔线样式
                                    type: 'dashed', //分隔线线的类型。
                                },
                            },
                            axisLine: { //坐标轴轴线相关设置。
                                show: true, //是否显示坐标轴轴线。
                                //onZero:false,//X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效。
                                //symbol:'arrow', //轴线两边的箭头, 默认不显示箭头，即 'none'
                                lineStyle: { //轴线样式
                                    width: 2,
                                    color: 'black',
                                    //opacity: 1, //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                                },
                            },
                            axisTick: { //坐标轴刻度相关设置。
                                show: true, //是否显示坐标轴刻度。
                                //alignWithLabel: true,//类目轴中在 boundaryGap 为 true 的时候有效，可以保证刻度线和标签对齐,default: false

                            }
                        },
                        yAxis: { //y轴
                            type: 'value',
                            splitArea: { //坐标轴在 grid 区域中的分隔区域，默认不显示。
                                //show: true
                            },
                            axisLabel: { //坐标轴刻度标签的相关设置。
                                //formatter: 'expr {value}',  // 使用字符串模板，模板变量为刻度默认标签 {value}
                                show: true, //是否显示刻度标签。
                                //interval: 'auto', //坐标轴刻度标签的显示间隔，在类目轴中有效。
                                color: 'black',
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed'
                                },
                            },
                            axisLine: {
                                show: true, //是否显示坐标轴轴线。
                                //onZero:false,//X 轴或者 Y 轴的轴线是否在另一个轴的 0 刻度上，只有在另一个轴为数值轴且包含 0 刻度时有效。
                                //symbol:'arrow', //轴线两边的箭头
                                lineStyle: {
                                    width: 2,
                                    color: 'black',
                                },
                            },
                        },
                        series: [
                            {
                                name: 'boxplot',//箱形图
                                type: 'boxplot',
                                //legendHoverLink: true, //是否启用图例 hover 时的联动高亮。
                                //hoverAnimation: false, //是否开启 hover 在 box 上的动画效果。
                                itemStyle: { //盒须图样式。
                                    //color: '#fff', //boxplot图形的颜色。 默认从全局调色盘 option.color 获取颜色
                                    borderColor: 'blue', //boxplot图形的描边颜色。支持的颜色格式同 color，不支持回调函数。
                                },
                                data: testData,
                                tooltip: { //注意：series.tooltip 仅在 tooltip.trigger 为 'item' 时有效。
                                    formatter: function (param) {
                                        return [
                                            '簇名: ' + param.name,
                                            '上边缘: ' + param.data[5],
                                            '上四分位数: ' + param.data[4],
                                            '中位数: ' + param.data[3],
                                            '下四分位数: ' + param.data[2],
                                            '下边缘: ' + param.data[1]
                                        ].join('<br/>')
                                    }
                                }
                            }
                        ]
                    };
                    myChart.setOption(option);


                    var yArr = [];
                    // var xArr =  [];

                    //debugger 0.04,0.13,0.35,0.7,0.35,0.13,0.04
                    var mean = parseFloat(datas[i].data.stdPlot.mean);
                    var stdev = parseFloat(datas[i].data.stdPlot.stdev);
                    var x = datas[i].data.stdPlot.xaxis.split(',');
                    var y = datas[i].data.stdPlot.yaxis.split(',')

                    //debugger
                    for(var j=0; j<x.length; j++){
                        var res = this.fun(x[j],mean,stdev).toFixed(2);
                        yArr.push(res);

                    }

                    var colors = ['#5793f3', '#d14a61', '#675bba'];
                    var option1 = {
                        color: colors,

                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross'
                            }
                        },
                        grid: {
                            right: '20%'
                        },
                        toolbox: {
                            feature: {
                                dataView: {show: true, readOnly: false},
                                restore: {show: true},
                                saveAsImage: {show: true}
                            }
                        },
                        legend: {
                            data:['原数据频率','正态分布']
                        },
                        xAxis: [
                            {
                                type: 'category',
                                axisTick: {
                                    alignWithLabel: true
                                },
                                data: x
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '频率',
                                position: 'right',
                                axisLine: {
                                    lineStyle: {
                                        color: colors[0]
                                    }
                                },
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            },
                            {
                                type: 'value',
                                position: 'left',
                                axisLine: {
                                    lineStyle: {
                                        color: colors[2]
                                    }
                                },
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            }
                        ],
                        series: [
                            {
                                name:'原数据频率',
                                type:'bar',
                                data:y
                            },
                            {
                                name:'正态分布',
                                type:'line',
                                smooth:true,
                                yAxisIndex: 1,
                                data:yArr
                            }
                        ]
                    };


                    myChart.setOption(option1);
                }







            }
        }
    }
</script>

<style scoped>

</style>
